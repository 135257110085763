import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'headerMenu',
    redirect:'index',
    component: () => import('../components/headerMenu.vue'),
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('../components/index.vue'),
      },
      {
        path: '/tts',
        name: 'tts',
        component: () => import('../components/tts.vue'),
      },
      {
        path: '/asr',
        name: 'asr',
        component: () => import('../components/asr.vue'),
      },
      {
        path: '/dsp',
        name: 'dsp',
        component: () => import('../components/dsp.vue'),
      },
      {
        path: '/voiceprintRecognition',
        name: 'voiceprintRecognition',
        component: () => import('../components/voiceprintRecognition.vue'),
      },
      {
        path: '/emotionRecognition',
        name: 'emotionRecognition',
        component: () => import('../components/emotionRecognition.vue'),
      },
      {
        path: '/phoneticInflection',
        name: 'phoneticInflection',
        component: () => import('../components/phoneticInflection.vue'),
      },
      {
        path: '/personalizedTTS',
        name: 'personalizedTTS',
        component: () => import('../components/personalizedTTS.vue'),
      },
      {
        path: '/smallLanguage',
        name: 'smallLanguage',
        component: () => import('../components/smallLanguage.vue'),
      },
      {
        path: '/stateAssessment',
        name: 'stateAssessment',
        component: () => import('../components/stateAssessment.vue'),
      },
      {
        path: '/deepAnalysis',
        name: 'deepAnalysis',
        component: () => import('../components/deepAnalysis.vue'),
      },
      {
        path: '/virtualHuman',
        name: 'virtualHuman',
        component: () => import('../components/virtualHuman.vue'),
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('../components/aboutUs.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
