<template>
  <div id="app">
    <router-view/>
    <!-- <HeaderMenu />
    <HelloWorld/>
    <FooterMenu /> -->
  </div>
</template>

<script>

// import HelloWorld from './components/index.vue'
// import HeaderMenu from './components/headerMenu.vue';
// import FooterMenu from './components/footerMenu.vue';
export default {
  name: 'App',
  components: {
    // HelloWorld,
    // HeaderMenu,
    // FooterMenu
  }
}
</script>

<style>
html, body{
  padding: 0;
  margin: 0;
  background-color: #fafafa;
  position: relative;
	font-family: SimHei;
}

.navTwo .el-menu{
  background:none;
}
.navTwo .el-menu--popup-bottom-start{
  margin: 0;
}
.navTwo .el-menu--popup{
  padding: 0;
}
.navTwo .el-menu .el-menu-item.is-active, .navTwo .el-menu .el-submenu.is-active>.el-submenu__title{
  color: #2295d1!important;
}
.navTwo .el-menu .el-menu-item:hover, .navTwo .el-menu .el-submenu:hover>.el-submenu__title{
  color: #2295d1!important;
}
</style>
